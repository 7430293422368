import React from "react";
import Layout from "../../components/layout/Layout.js";
import SEO from "../../components/SEO.js";
import Header from "../../components/layout/Header.js";
import SiteTitleQuery from "../../components/SiteTitleQuery.js";
import HistoryImage from "../../images/inHouseImages/historyImage.jpg";

function History() {
  return (
    <SiteTitleQuery
      render={data => (
        <Layout title={data.site.siteMetadata.title}>
          <SEO title="Historia" keywords={[`Psk`, `PSKSyd`]} />
          <Header data={data} />

          <div className="container mx-auto flex flex-col md:flex-row items-center my-8 md:my-10 justify-center">
            <div className="sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-3xl rounded overflow-hidden shadow">
              <img
                className="w-full"
                src={HistoryImage}
                alt="PSKSyd logo"
                title="PSKSyd logo"
              ></img>
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Om PSK Syd AB</div>
                <p className="text-gray-700 text-base">
                  1989 startades företaget PSK Syd AB där de tre första
                  bokstäverna stod för Petroleum System Konsult, vilket
                  indikerade att de primära kunderna var inom
                  drivmedelsbranschen. Arbetet bestod i början av att mäta
                  diverse avstånd på bensinstationerna och sedan rita in dom på
                  kartor för att säkerställa att myndighetskrav uppfylldes. De
                  tidiga arbetsredskapen var måttband och ritbord med linjaler i
                  bords-arm, samt olika typer av pennor.
                  <br></br>
                  <br></br>
                  PSK var länge ett fåmansföretag med Göran Ström och Lennart
                  Knutas som stomme. I flera år åkte de land och rike runt för
                  att mäta och inventera bensinstationer för sina kunder, vilket
                  med tiden var alla större drivmedelsbolag i landet. De två har
                  troligtvis varit på fler bensinstationer än någon annan i
                  Sverige.
                  <br></br>
                  <br></br>
                  Tidigt insåg man fördelarna med att använda datorer för att
                  göra digitaliserade ritningar med CAD istället för ritbord,
                  linjaler och pennor. Göran Ström, som hade ett stort
                  teknikintresse och en stark tro på att allt går, lärde sig att
                  utveckla applikationer. Han utvecklade ett register som
                  innehöll stationerna som mättes upp, information om dessa,
                  samt lade ritningarna på respektive station.
                  <br></br>
                  När uppdragsgivarna, som tekniskt ansvariga för stationerna,
                  blev visade systemet såg de en applikation med precis den
                  information de behövde, utvecklad och färdig. Man kom överens
                  om att ge dem tillgång till applikationen och datan som fanns
                  där. Ett långt samarbete var nu påbörjat och PSK fortsatte att
                  utveckla systemet och introducerade det till fler kunder.
                  <br></br>
                  <br></br>
                  PSKs främsta tillgång var deras stora kunskap om regler,
                  myndighetskrav samt förutsättningarna för branschen. PSK
                  fortsatte arbeta som konsulter, genomförde mätningar,
                  CAD-ritande och konsultuppdrag rörande driften av
                  bensinstationer, parallellt som man utvecklade sitt
                  stationssystem.
                  <br></br>
                  <br></br>
                  När Göran Ström och Lennart Knutas lämnade över till nästa
                  generation startade en expansion för att möta kundernas ökade
                  krav, såväl inom konsult-sidan som inom systemutveckling. Ett
                  stort teknikskifte öppnade också upp för att bredda systemet,
                  och PSK riktar sig nu till alla branscher där man har
                  anläggningar man behöver möta myndighetskrav på.
                  <br></br>
                  <br></br>
                  Idag erbjuder PSK flera konsulttjänster och ett system som
                  löpande kommer i nya versioner, som används av såväl samtliga
                  större drivmedelsaktörer i Sverige samt flera utanför landets
                  gränser men även bolag inom helt andra branscher. Att PSK
                  arbetat mot en av de hårdast reglerade marknaderna i landet
                  gör att processer och rutiner finns för de flesta
                  myndighetskrav. Även hantering av Felanmälan och
                  Myndighetsärenden har blivit en stor del verksamheten, och
                  kompletterat servicen PSK kan ge både gamla och nya kunder.
                  <br></br>
                  <br></br>
                  Idag har PSK ca 20 anställda och växer med utökat förtroende
                  från våra kunder.
                </p>
              </div>
            </div>
          </div>
        </Layout>
      )}
    />
  );
}

export default History;
